<template>
  <div class="game-container layout-border">
    <FindStroke
      :bgImg="bgImg"
      :gameIndex="gameIndex"
      :strokeImgList="strokeImgList"
      :strockNotFoundImage="strockNotFoundImage"
      :strockFoundImage="strockFoundImage"
    />
  </div>
</template>

<script>
import FindStroke from "@/components/Course/GamePage/FindStrokeGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-18-1/background-cruise.svg"),
      gameIndex:10,
      strokeImgList: [
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-18-1/stoke-jellyfish.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-18-1/stoke-jellyfish-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-18-1/stroke-anchor.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-18-1/stroke-anchor-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-18-1/stroke-hook.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-18-1/stroke-hook-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-18-1/stroke-ship.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-18-1/stroke-ship-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-18-1/stroke-whale.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-18-1/stroke-whale-found.svg"),
        },
      ],
      strockNotFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-18-1/stroke-shuwangou.svg"),
      strockFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-18-1/stroke-shuwangou-found.svg"),
    };
  },
  components: {
    FindStroke,
  },
};
</script>

<style lang="scss" scoped>
</style>