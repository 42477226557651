<template>
  <div class="game-container">
    <SpeakingSentence
      :titleInfo="titleInfo"
      :buttonList="buttonList"
      :gameList="gameList"
      @initGame="initGame"
      @showSentence="showSentence"
      @showSecondImg="showSecondImg"
      :isTwoImg="true"
      :lesson="30"
    />
  </div>
</template>

<script>
import SpeakingSentence from "@/components/Course/CoursePage/SpeakingSentence";
export default {
  data() {
    return {
      originGameList:[],
      titleInfo: {
        hanzi: "他/她是谁？",
        pinyin: "Tā/tā shì shéi?",
      },
      gameList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-18/1-dongdong-shei.svg`),
          secondImg:require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-18/1-dongdong.svg`),
          pinyin: "Tā shì Dōngdōng.",
          hanzi: "他是冬冬。",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-18/2-tutu-shei.svg`),
           secondImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-18/2-tutu.svg`),
          pinyin: "Tā shì Tútu.",
          hanzi: "他是图图。",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-18/3-xiaotian-shei.svg`),
          secondImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-18/3-xiaotian.svg`),
          pinyin: "Tā shì Xiǎotiān.",
          hanzi: "他是小天。",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-18/4-xiaoai-shei.svg`),
          secondImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-18/4-xiaoai.svg`),
          pinyin: "Tā shì Xiǎo'ài.",
          hanzi: "她是小爱。",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-18/5-laoshi-shei.svg`),
          secondImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-18/5-laoshi.svg`),
          pinyin: "Tā shì lǎoshī.",
          hanzi: "她是老师。",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-18/6-xiaoai-shei.svg`),
          secondImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-18/6-xiaoai.svg`),
          pinyin: "Tā shì Xiàxia.",
          hanzi: "她是夏夏。",
          isShowSentence: false,
        },
      ],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-18/question-yellow.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-18/question-white.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-18/question-red.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
         {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-18/question-yellow.svg"),
          startNumberRange: 4,
          endNumberRange: 4,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-18/question-white.svg"),
          startNumberRange: 5,
          endNumberRange: 5,
        },
         {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-18/question-red.svg"),
          startNumberRange: 6,
          endNumberRange: 6,
        },
      ],
    };
  },
  created() {
this.originGameList = JSON.parse(JSON.stringify(this.gameList))

  },
  components: {
    SpeakingSentence,
  },
  methods: {
    initGame() {
      this.gameList.forEach((item,index) => {
        item.isShowSentence = false;
        item.bgImg = this.originGameList[index].bgImg
      });
    },
    showSentence(index) {
      this.gameList[index - 1].isShowSentence = true;
    },
    showSecondImg(index) {
      this.gameList[index - 1].bgImg = this.gameList[index - 1].secondImg;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>