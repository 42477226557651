<!--
 * @Author: your name
 * @Date: 2022-03-16 10:06:35
 * @LastEditTime: 2022-03-22 14:45:32
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nihaokidsNew-front\src\views\Course\Level1\Chapter3\Lesson18\12_PracticePageTwo_shuwangou.vue
-->
<template>
  <div class="game-container layout-border">
    <FindStrokeTrain
      :targetStrokeImg="targetStrokeImg"
      :gameIndex="gameIndex"
      :hanziStrokeInfo="hanziStrokeInfo"
      :lightIndex="lightIndex"
      :strokePinyin="strokePinyin"
      @showStroke="handleShowStroke"
      :shadowImg="shadowImg"
      :headerBgImg="headerBgImg"
    />
  </div>
</template>

<script>
import FindStrokeTrain from "@/components/Course/GamePage/FindStrokeTrainGame";
export default {
  data() {
    return {
      headerBgImg: {
        defaultImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/hedgehog-1.svg"),
        firstImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/hedgehog-2.svg"),
        secondImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/hedgehog-3.svg"),
        thirdImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/hedgehog-4.svg"),
      },
      shadowImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-18-1/cherry.svg"),
      targetStrokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-18-1/lesson-18-shuwangou-white.svg"),
      strokePinyin: "shùwāngōu",
      gameIndex: 9,
      lightIndex: [1, 2, 3],
      hanziStrokeInfo: [
        {
          pinyin: "qī",
          strokeBgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-18-1/qi-grey.svg"),
          strokeFinishImgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-18-1/qi-1.svg"),
              isShow: false,
              index: 1,
            },
          ],
        },
        {
          pinyin: "tā",
          strokeBgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-18-1/ta-grey.svg"),
          strokeFinishImgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-18-1/ta-1.svg"),
              isShow: false,
              index: 2,
            },
          ],
        },
        {
          pinyin: "cǐ",
          strokeBgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-18-1/ci-grey.svg"),
          strokeFinishImgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-18-1/ci-1.svg"),
              isShow: false,
              index: 3,
            },
          ],
        },
      ],
    };
  },
  components: {
    FindStrokeTrain,
  },
  methods: {
    handleShowStroke(index, indexSon) {
      this.hanziStrokeInfo[index].strokeFinishImgList[indexSon].isShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>